import React from "react"

import Button from "components/Button"

import { useCustomer } from "context/customer"

import { getOrderStatus } from "utils/functions"

import IcoDownload from "assets/icons/download-button.svg"

const PartnerOrders = ({ setPopup }) => {
  const { customer } = useCustomer()

  return (
    <div className="customer-panel-orders">
      {customer?.partnerOrders?.length > 0 &&
        customer?.partnerOrders?.map((item, index) => (
          <div className="customer-panel-orders__item" key={index}>
            <div className="customer-panel-orders__item-header">
              <h4>
                Numer transakcji: {item?.orderNumber},{" "}
                {item.date?.split("T")?.[0]?.split("-").reverse().join(".")}
                <br />
                {item?.customer?.firstName} {item?.customer?.lastName}
                <br />
                Partner ID: {item?.partnerId}
                <br />
                Status: {getOrderStatus(item.status)?.label}
              </h4>
              {item?.pdfFile && (
                <a
                  className="button"
                  href={item?.pdfFile}
                  target="_blank"
                  rel="noreferrer"
                >
                  Pobierz umowę
                  <img src={IcoDownload} alt="" />
                </a>
              )}
            </div>
            <div className="customer-panel-orders__item-row">
              <div>
                <h5>Kwota inwestycji</h5>
                <h3>
                  <div dangerouslySetInnerHTML={{ __html: item.total }} />
                </h3>
              </div>
              <div>
                <h5>Lista dokumentów</h5>
                <ul>
                  {item?.clientType === "individual" &&
                    item?.lineItems?.nodes[0]?.product?.acfProduct?.dokumentyOsobaFizyczna?.map(
                      (node, index) => (
                        <li key={index}>
                          <a
                            href={node?.plik?.mediaItemUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Załącznik nr {index + 1} - {node?.nazwaPliku}
                          </a>
                        </li>
                      )
                    )}
                  {item?.clientType === "company" &&
                    item?.lineItems?.nodes[0]?.product?.acfProduct?.dokumentyOsobaPrawna?.map(
                      (node, index) => (
                        <li key={index}>
                          <a
                            href={node?.plik?.mediaItemUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Załącznik nr {index + 1} - {node?.nazwaPliku}
                          </a>
                        </li>
                      )
                    )}

                  {parseInt(item?.total?.replace(",00 zł", "")) > 25000
                    ? item?.lineItems?.nodes[0]?.product?.acfProduct?.dokumentyKwotaPowyzej25Tys?.map(
                        (node, index) => (
                          <li key={index}>
                            <a
                              href={node?.plik?.mediaItemUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Załącznik nr {index + 1} - {node?.nazwaPliku}
                            </a>
                          </li>
                        )
                      )
                    : item?.lineItems?.nodes[0]?.product?.acfProduct?.dokumentyKwotaPonizej25Tys?.map(
                        (node, index) => (
                          <li key={index}>
                            <a
                              href={node?.plik?.mediaItemUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Załącznik nr {index + 1} - {node?.nazwaPliku}
                            </a>
                          </li>
                        )
                      )}
                </ul>

                {item?.annex_to_contracts?.annexToContracts?.length > 0 && (
                  <ul>
                    {item?.annex_to_contracts?.annexToContracts?.map(
                      (node, index) => (
                        <li key={index}>
                          Aneks nr {index + 1} -{" "}
                          <a
                            href={node?.file?.mediaItemUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {node?.description}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </div>
            <div className="customer-panel-orders__item-cta">
              <Button
                className="button--blue"
                onClick={() => setPopup(item.orderNumber)}
              >
                kontakt z ekspertem
              </Button>
            </div>
          </div>
        ))}

      {!customer?.partnerOrders?.length > 0 && (
        <p className="customer-panel-orders__no-results">
          Na Twoim koncie nie ma obecnie żadnych umów partnerskich.
        </p>
      )}
    </div>
  )
}

export default PartnerOrders
