/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "./styles.scss"

import React from "react"

import LazyPlaceholder from "components/LazyPlaceholder"

import { ContractForm, NewContractForm } from "./components"

import { useCustomer } from "context/customer"

const Popup = ({ popup, setPopup }) => {
  const { loading, customer, error } = useCustomer()

  if (loading) return <LazyPlaceholder />
  if (error) return <p>{error.message}</p>

  return (
    <>
      <div className="customer-panel-popup">
        <div className="invest-baner__wrapper">
          <h3>
            {popup === "contract"
              ? "Kontakt w sprawie obecnej umowy"
              : "Kontakt"}
          </h3>
          <button
            className="invest-baner__close"
            onClick={() => setPopup(false)}
          >
            Close
          </button>
          {popup === "new_contract" ? (
            <NewContractForm customer={customer} />
          ) : (
            <ContractForm customer={customer} orderNumber={popup} />
          )}
        </div>
      </div>
      <div className="invest-baner__overlay" onClick={() => setPopup(false)} />
    </>
  )
}

export default Popup
