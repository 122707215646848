import "./styles.scss"

import React from "react"
import { useCustomer } from "context/customer"

import IcoCaution from "assets/icons/caution.svg"

const Settings = () => {
  const { customer } = useCustomer()

  return (
    <div className="customer-panel-settings">
      <div className="customer-panel-settings__wrapper">
        <div className="customer-panel-settings__item">
          <strong>Imię i nazwisko</strong>
          <p>
            {customer?.firstName} {customer?.lastName}
          </p>
        </div>
        <div className="customer-panel-settings__item">
          <strong>Numer telefonu</strong>
          <p>{customer?.billing?.phone || "-"}</p>
        </div>
        <div className="customer-panel-settings__item">
          <strong>Adres email</strong>
          <p>{customer?.email || "-"}</p>
        </div>
        <div className="customer-panel-settings__item">
          <strong>Adres korespondencyjny</strong>
          <address>
            {customer?.billing?.address1 || "-"}
            <br />
            {customer?.billing?.postcode || "-"}{" "}
            {customer?.billing?.city || "-"}
          </address>
        </div>
        <p className="customer-panel-settings__info">
          <img src={IcoCaution} alt="" />
          Jeżeli Twoje dane są błędne, prosimy o kontakt.
        </p>
      </div>
    </div>
  )
}

export default Settings
