import "./styles.scss"

import React, { useState } from "react"

import LazyPlaceholder from "components/LazyPlaceholder"

import { Orders, Settings, PartnerOrders } from "./components"

import { useCustomer } from "context/customer"

const Content = ({ popup, setPopup }) => {
  const [nav, setNav] = useState("orders")
  const { customer, loading, error } = useCustomer()

  if (loading) return <LazyPlaceholder />
  if (error) return <p>{error.message}</p>

  const contentData = { popup, setPopup }

  return (
    <section className="customer-panel-content">
      <div className="container-fluid">
        <div className="customer-panel-content__nav">
          <button
            className={nav === "orders" ? "current" : ""}
            onClick={() => setNav("orders")}
          >
            Moje umowy
          </button>
          {customer?.showPartnerOrder && (
            <button
              className={nav === "orders-partner" ? "current" : ""}
              onClick={() => setNav("orders-partner")}
            >
              Umowy partnerskie
            </button>
          )}
          <button
            className={nav === "settings" ? "current" : ""}
            onClick={() => setNav("settings")}
          >
            Moje dane
          </button>
        </div>

        {nav === "orders" && <Orders {...contentData} />}
        {nav === "settings" && <Settings />}
        {nav === "orders-partner" && <PartnerOrders {...contentData} />}
      </div>
    </section>
  )
}

export default Content
